import gql from "graphql-tag";

export const reviewJobsGql = gql`
  mutation ReviewJobs($input: ReviewJobsInput!) {
    reviewJobs(input: $input) {
      ... on GenericSuccess {
        __typename
        success
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message

          ... on InvalidInputError {
            field
          }
        }
      }
    }
  }
`;
