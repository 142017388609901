
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import RequestCard from "@/shared/components/Requests/RequestCard.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { useAsyncScroll } from "@/shared/composables/useAsyncScroll";
import {
  useReviewRequests,
  COMMENTS_LOCAL_STORAGE_THROTTLE_DURATION,
} from "@/shared/composables/useReviewRequests";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { useStepBar, StepBarLocation } from "@/shared/composables/useStepBar";
import { CheckOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { redirectToReviewRequests } = useRedirectHelper();

    const isReviewing = String(route.query.reviewing) === "true";
    const orderId = ref(String(route.params.orderId));

    const {
      requests,
      selectedRequestIds,
      selectedRequests,
      requestsLoading,
      requestsFetchMore,
      requestsFetchMoreLoading,
      requestsHasNextPage,
      refetchRequests,
      loading,
      currentFormState,
      handleBatchSubmit,
      batchSubmitLoading,
      commentOptions,
      throttledTrySaveCommentData,
      tryLoadCommentData,
    } = useReviewRequests(orderId);

    useAsyncScroll({ ref: requests, hash: route.hash });

    onMounted(() => {
      if (isReviewing) {
        if (!selectedRequestIds.value.length) {
          return redirectToReviewRequests({ orderId: orderId.value });
        }

        tryLoadCommentData();
      }
    });

    const { options: stepBarOptions } = useStepBar(StepBarLocation.requests, 1);

    return {
      COMMENTS_LOCAL_STORAGE_THROTTLE_DURATION,
      t,
      makeRequiredRule,

      requests: computed(() =>
        isReviewing ? selectedRequests.value : requests.value
      ),
      requestsLoading,
      requestsFetchMore,
      requestsFetchMoreLoading,
      requestsHasNextPage,
      refetchRequests,
      isReviewing,
      loading,
      currentFormState,
      handleBatchSubmit,
      batchSubmitLoading,
      commentOptions,
      stepBarOptions,
      throttledTrySaveCommentData,
    };
  },
  components: {
    PageLayout,
    RequestCard,
    FormGroup,
    InputTextArea,
    InputCheckbox,
    CheckOutlined,
  },
});
