import { nextTick, Ref, watch, onUnmounted } from "vue";

export type UseAsyncScrollOptions = {
  /** A Ref reactive value to watch */
  ref: Ref;
  /** The hash value from `route.hash`. Can be with or without # symbol. */
  hash: string;
};

/**
 * A composable to try and scroll into view the HTMLElement that a specific hash (#id) from URL points to.
 * This listens to changes in a Vue Ref value to trigger the searching of the element.
 * Useful when HTMLElement is loaded asynchronously via API, and is not immediately visible in DOM.
 * @param options Options used by `useAsyncScroll`. See {@link UseAsyncScrollOptions}
 */
export const useAsyncScroll = (options: UseAsyncScrollOptions) => {
  if (!options.hash) return;

  const unwatch = watch(options.ref, () => {
    setTimeout(() => {
      nextTick(() => {
        const target = document.getElementById(options.hash.replace("#", ""));

        console.log(options.ref.value);
        console.log(options.hash);
        console.log(target);

        if (target) {
          target.scrollIntoView({
            behavior: "smooth",
          });

          unwatch();
        }
      });
    }, 1000);
  });

  onUnmounted(() => {
    unwatch();
  });
};
