import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "text-center my-28 max-w-5xl m-auto" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "mt-10"
}
const _hoisted_4 = { class: "text-center my-8" }
const _hoisted_5 = {
  key: 1,
  class: "my-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_CheckOutlined = _resolveComponent("CheckOutlined")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_RequestCard = _resolveComponent("RequestCard")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "requests-page",
    "back-button": "",
    onOnBack: _ctx.$router.back,
    stepBarOptions: _ctx.isReviewing ? _ctx.stepBarOptions : null
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, {
          level: 2,
          class: "m-0 mb-5"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isReviewing
              ? _ctx.t("Step 2: Finalize your choices")
              : _ctx.t("Current Order")), 1)
          ]),
          _: 1
        }),
        (_ctx.isReviewing)
          ? (_openBlock(), _createBlock(_component_a_typography, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(
              `Write your notes for each video so we can review and revise accordingly.
            If it's good to go, please leave the note blank.
            You will only be able to submit these revisions all at once so kindly confirm
            before you click Done.`
            )), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.loading && !_ctx.requestsFetchMoreLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FormGroup, {
              model: _ctx.currentFormState,
              loading: _ctx.batchSubmitLoading,
              onFinish: _ctx.handleBatchSubmit,
              onInput: _ctx.throttledTrySaveCommentData
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentFormState.comments, (state, index) => {
                  return (_openBlock(), _createBlock(_component_RequestCard, {
                    key: state?.id,
                    id: state?.id,
                    request: _ctx.requests?.find((r) => r?.id === state.id),
                    onOnVideoRefresh: _ctx.refetchRequests,
                    "show-comments": _ctx.isReviewing,
                    "use-small-titles": _ctx.isReviewing,
                    "is-reviewing": _ctx.isReviewing
                  }, _createSlots({ _: 2 }, [
                    (_ctx.isReviewing)
                      ? {
                          name: "extra",
                          fn: _withCtx(() => [
                            _createVNode(_component_a_typography_title, {
                              level: 5,
                              class: "mt-12 mb-8"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("What do you think of the output?")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_InputCheckbox, {
                              class: "requests-page__comment-checkbox",
                              value: _ctx.currentFormState.comments[index].disabled,
                              "onUpdate:value": ($event: any) => ((_ctx.currentFormState.comments[index].disabled) = $event),
                              name: ['comments', index, 'disabled'],
                              "check-label": _ctx.t(`❤️ Approved! I'm good with the video.`)
                            }, null, 8, ["value", "onUpdate:value", "name", "check-label"]),
                            _createVNode(_Transition, {
                              name: "fade",
                              mode: "out-in",
                              appear: ""
                            }, {
                              default: _withCtx(() => [
                                (!_ctx.currentFormState.comments[index].disabled)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                      _createVNode(_component_InputCheckbox, {
                                        class: "requests-page__comment-checkbox",
                                        label: _ctx.t('Please tell us why'),
                                        value: 
                    _ctx.currentFormState.comments[index].selectedChoices
                  ,
                                        "onUpdate:value": ($event: any) => ((
                    _ctx.currentFormState.comments[index].selectedChoices
                  ) = $event),
                                        name: ['comments', index, 'selectedChoices'],
                                        rules: [
                    _ctx.makeRequiredRule(
                      _ctx.t('Please choose one of the above.'),
                      'array'
                    ),
                  ],
                                        options: _ctx.commentOptions,
                                        multiple: "",
                                        vertical: ""
                                      }, null, 8, ["label", "value", "onUpdate:value", "name", "rules", "options"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_divider, { class: "border-t-4 mt-12 mb-7" }),
                            _createVNode(_component_a_typography_title, {
                              level: 5,
                              class: "mb-7"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Notes")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_Transition, {
                              name: "fade",
                              mode: "out-in",
                              appear: ""
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createVNode(_component_InputTextArea, {
                                  value: _ctx.currentFormState.comments[index].text,
                                  "onUpdate:value": ($event: any) => ((_ctx.currentFormState.comments[index].text) = $event),
                                  name: ['comments', index, 'text'],
                                  placeholder: _ctx.t('Please leave your comments here.'),
                                  "update-tracker-delay": _ctx.COMMENTS_LOCAL_STORAGE_THROTTLE_DURATION,
                                  class: "requests-page__comment-textarea"
                                }, {
                                  "lower-right": _withCtx(({ isDoneUpdating }) => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["success-color transition-opacity text-xs", [isDoneUpdating ? 'opacity-100' : 'opacity-0']])
                                    }, [
                                      _createVNode(_component_CheckOutlined, { class: "mr-1" }),
                                      _createElementVNode("span", null, _toDisplayString(_ctx.t("Saved as draft")), 1)
                                    ], 2)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "onUpdate:value", "name", "placeholder", "update-tracker-delay"]), [
                                  [_vShow, !_ctx.currentFormState.comments[index].disabled]
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        }
                      : undefined
                  ]), 1032, ["id", "request", "onOnVideoRefresh", "show-comments", "use-small-titles", "is-reviewing"]))
                }), 128)),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.requestsHasNextPage && !_ctx.isReviewing)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        onClick: _ctx.requestsFetchMore,
                        "html-type": "button",
                        loading: _ctx.requestsLoading || _ctx.requestsFetchMoreLoading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Load More")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "loading"]))
                    : _createCommentVNode("", true),
                  (_ctx.isReviewing)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_a_typography_paragraph, { class: "mb-8" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t(
                  "Once you finalize the above, click Done to download the videos or submit any revisions for the model creator."
                )), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_button, {
                          "html-type": "submit",
                          type: "primary",
                          size: "large",
                          loading: _ctx.batchSubmitLoading
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Done")), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["model", "loading", "onFinish", "onInput"])
          ]))
    ]),
    _: 1
  }, 8, ["onOnBack", "stepBarOptions"]))
}