import { reactive } from "vue";
import { i18nTranslate as t } from "@/plugins/i18n";

export enum StepBarLocation {
  requests = "requests",
}

export type TypeCurrentStep = number | null;

export type TypeStepBarSteps = {
  title: string;
  description?: string;
  subTitle?: string;
  status?: string;
  disabled?: boolean;
};

export type TypeStepBarOptions = {
  steps: [TypeStepBarSteps];
  currentStep: TypeCurrentStep;
};

const currentStepsAll = reactive({
  requests: 0,
});

const stepsAll = {
  requests: [
    {
      title: t("Step 1"),
      description: t("Choose your preferred videos"),
    },
    {
      title: t("Step 2"),
      description: t("Finalize your choices"),
    },
    {
      title: t("Step 3"),
      description: t("Download Videos"),
    },
  ],
};

/**
 * Get all information or options of all StepBar components
 * @param key The object key to determine which feature/location is the StepBar
 * component being used
 * @param fixedCurrentStep Supply if you need to display the StepBar component
 * to a certain step and not based on the reactive variavble
 */
export const useStepBar = (
  key: StepBarLocation,
  fixedCurrentStep: TypeCurrentStep = null
) => {
  const steps = stepsAll[key];
  const currentStep = currentStepsAll[key];
  const options = {
    steps,
    currentStep: fixedCurrentStep ?? currentStep,
  };

  return {
    stepsAll,
    steps,
    currentStepsAll,
    currentStep,
    options,
  };
};
