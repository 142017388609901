import { useRequests } from "@/shared/composables/useRequests";
import {
  ReviewJobs,
  ReviewJobsVariables,
  ReviewJobs_reviewJobs_GenericSuccess,
} from "@/api/jobs/__generated__/ReviewJobs";
import { JobCommentInputType } from "@/../__generated__/globalTypes";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { ref, computed, reactive, Ref, watch, watchEffect } from "vue";
import { useOrder } from "@/shared/composables/useOrder";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { reviewJobsGql } from "@/api/jobs/reviewJobs";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { JobStatus } from "@/shared/types/JobStatus";
import { i18nTranslate } from "@/plugins/i18n";
import { useRedirectHelper } from "./useRedirectHelper";
import throttle from "lodash/throttle";
import { LocalStorageKeys } from "@/shared/utils/localStorage";

export const COMMENTS_LOCAL_STORAGE_THROTTLE_DURATION = 2000;

export type CommentsData = {
  id: string;
  /** Identifies if will send this data to BE. Default=true (I'm good with the video) */
  disabled: boolean;
  selectedChoices: string[];
  text: string;
}[];

/**
 * Label contains emojis. Values to pass to BE should not.
 */
export const commentOptions = [
  {
    label: i18nTranslate("😅 The script was not followed"),
    value: i18nTranslate("The script was not followed."),
  },
  {
    label: i18nTranslate(
      "⚠️ Wrong information about the brand and/or product. "
    ),
    value: i18nTranslate("Wrong information about the brand and/or product. "),
  },
  {
    label: i18nTranslate("😖 Grammar error / typo."),
    value: i18nTranslate("Grammar error / typo."),
  },
  {
    label: i18nTranslate("😭 Mispronunciation."),
    value: i18nTranslate("Mispronunciation."),
  },
  {
    label: i18nTranslate("🎵 Change background music."),
    value: i18nTranslate("Change background music."),
  },
];

// === Global State (to be accessible to multiple components) ===

/** Form State saved in localStorage */
const savedFormState = reactive({
  comments: [] as CommentsData,
});
// LocalStorage: Load initial
savedFormState.comments = JSON.parse(
  window.localStorage.getItem(LocalStorageKeys.JOB_COMMENTS) ?? "[]"
) as CommentsData;

/** Selected IDs in Step 1 */
const selectedRequestIds = ref<string[]>([]);
/** Form State for the current Order ID */
const currentFormState = reactive({
  comments: [] as CommentsData,
});

const commentedRequestIds = computed<string[]>(() =>
  currentFormState.comments
    .filter(
      (state) => selectedRequestIds.value.includes(state.id) && !state.disabled
    )
    .map((state) => state.id)
);

/** Approved Job IDs are those not for comments */
const approvedRequestIds = computed(() =>
  selectedRequestIds.value.filter(
    (id) => !commentedRequestIds.value.includes(id)
  )
);

const commentsInput = computed<JobCommentInputType[]>(() => {
  return commentedRequestIds.value.map((id) => {
    const state = currentFormState.comments.find((state) => state.id === id);

    // Create entire comment from selected choices
    const choicesText = state?.selectedChoices
      .map((choice, i) => `${i + 1}. ${choice}`)
      .join("\n");

    const notesText = state?.text;

    return {
      jobId: id,
      comment: `${choicesText}\n${notesText}`.trim(),
    };
  });
});

/**
 * A composable to use for handling batch review of jobs/requets.
 */
export const useReviewRequests = (orderId: Ref<string>) => {
  const { redirectToRequests, redirectToDownloadRequests } =
    useRedirectHelper();

  const resetState = () => {
    // Clear in localStorage
    try {
      selectedRequestIds.value.forEach((id) => {
        const savedIndex = savedFormState.comments.findIndex(
          (saved) => saved.id === id
        );

        if (savedIndex > -1) {
          savedFormState.comments.splice(savedIndex, 1);
        }
      });

      window.localStorage.setItem(
        LocalStorageKeys.JOB_COMMENTS,
        JSON.stringify(savedFormState.comments)
      );
    } catch (e) {
      console.error(e);
    }

    currentFormState.comments = [];
    selectedRequestIds.value = [];
  };

  // === Queries ===
  const { order, orderLoading } = useOrder({ orderId });
  const {
    requests,
    requestsLoading,
    requestsFetchMore,
    requestsFetchMoreLoading,
    requestsHasNextPage,
    refetchRequests,
    requestsFilter,
  } = useRequests();

  watchEffect(() => {
    if (requests.value?.length) {
      currentFormState.comments = [];

      const newState: CommentsData = [];
      // Init form
      requests.value.forEach((r) => {
        newState.push({
          id: r!.id,
          selectedChoices: [],
          disabled: true,
          text: "",
        });
      });

      currentFormState.comments = newState;
    }
  });

  watch(
    orderId,
    (id) => {
      if (id) {
        requestsFilter.orderId = Number(id);
      }
    },
    { immediate: true }
  );

  const {
    currentSubscriptionPlan,
    currentSubscriptionPlanLoading,
    maxVideoCount: subscriptionPlanVideoCount,
  } = useSubscriptionPlans();

  /** Max number of videos to approve, prioritize quotation of order */
  const maxVideoCount = computed(
    () =>
      order.value?.quotation?.numOfVideos || subscriptionPlanVideoCount.value
  );

  // === Mutations ===
  const { mutate: reviewJobsMutate, loading: batchSubmitLoading } =
    useCustomMutation<ReviewJobs, ReviewJobsVariables>(reviewJobsGql);

  const selectedRequests = computed(() =>
    requests.value?.filter((item) =>
      selectedRequestIds.value.includes(item?.id ?? "-1")
    )
  );

  /** Call after selecting videos */
  const handleFinishStep1 = () => {
    redirectToRequests({ orderId: orderId.value, reviewing: true });
  };

  const handleSelectedChange = (selectedId: string) => {
    const foundIndex = selectedRequestIds.value.findIndex(
      (id) => id === selectedId
    );
    const found = foundIndex > -1;

    // Remove from selected
    if (found) {
      selectedRequestIds.value.splice(foundIndex, 1);
    }
    // Add to selected and currentFormState
    else {
      selectedRequestIds.value.push(selectedId);
    }
  };

  /** Call for adding comments */
  const handleBatchSubmit = async () => {
    try {
      // Review Jobs in batch
      const response = await reviewJobsMutate({
        input: {
          orderId: order.value?.id ?? "",
          approvedJobIds: approvedRequestIds.value,
          comments: commentsInput.value,
        },
      });

      const parsedResponse =
        parseGqlResponse<ReviewJobs_reviewJobs_GenericSuccess>(
          "GenericSuccess",
          response
        );

      if (!parsedResponse.data?.success) {
        return;
      }

      resetState();

      redirectToDownloadRequests({ orderId: orderId.value });

      await refetchRequests();
    } catch (e) {
      console.error(e);
    }
  };

  /** LocalStorage: Load savedFormState -> currentFormState */
  const tryLoadCommentData = () => {
    try {
      currentFormState.comments.forEach((state, index) => {
        const savedState = savedFormState.comments.find(
          (saved) => saved.id === state.id
        );

        if (savedState) {
          currentFormState.comments[index].disabled = savedState.disabled;
          currentFormState.comments[index].selectedChoices =
            savedState.selectedChoices;
          currentFormState.comments[index].text = savedState.text;
        }
      });
    } catch (e) {
      console.error("Error in loading comment data from localStorage:", e);
    }
  };

  /** LocalStorage: Save currentFormState -> savedFormState */
  const trySaveCommentData = () => {
    try {
      currentFormState.comments.forEach((current) => {
        const savedStateIndex = savedFormState.comments.findIndex(
          (saved) => saved.id === current.id
        );

        if (savedStateIndex > -1) {
          const savedState = savedFormState.comments[savedStateIndex];
          savedFormState.comments[savedStateIndex] = {
            ...savedState,
            disabled: current.disabled,
            selectedChoices: current.selectedChoices,
            text: current.text,
          };
        } else {
          savedFormState.comments.push({
            id: current.id,
            disabled: current.disabled,
            selectedChoices: current.selectedChoices,
            text: current.text,
          });
        }
      });

      window.localStorage.setItem(
        LocalStorageKeys.JOB_COMMENTS,
        JSON.stringify(savedFormState.comments)
      );
    } catch (e) {
      console.error("Error in saving to comment data localStorage:", e);
    }
  };

  /** Helper function to preselect CLIENT_APPROVED and CLIENT_COMMENTED jobs */
  const preselectApprovedRequests = () => {
    selectedRequestIds.value = [];

    (
      requests.value
        ?.filter(
          (request) =>
            request &&
            request.id &&
            [JobStatus.CLIENT_APPROVED, JobStatus.CLIENT_COMMENTED].includes(
              request.status?.key ?? ""
            )
        )
        .map((request) => request?.id) as string[]
    ).forEach((id) => handleSelectedChange(id));
  };

  return {
    handleFinishStep1,
    handleSelectedChange,
    handleBatchSubmit,
    preselectApprovedRequests,
    tryLoadCommentData,
    throttledTrySaveCommentData: throttle(
      trySaveCommentData,
      COMMENTS_LOCAL_STORAGE_THROTTLE_DURATION
    ),

    currentFormState,
    commentOptions,

    requests,
    requestsLoading,
    requestsFetchMore,
    requestsFetchMoreLoading,
    requestsHasNextPage,
    refetchRequests,

    currentSubscriptionPlan,
    maxVideoCount,

    selectedRequestIds,
    selectedRequests,
    isSelectionValid: computed(
      () => selectedRequestIds.value.length == maxVideoCount.value
    ),
    isAdditionalSelectionAllowed: computed(
      () => selectedRequestIds.value.length < maxVideoCount.value
    ),

    batchSubmitLoading,
    loading: computed<boolean>(
      () =>
        requestsLoading.value ||
        currentSubscriptionPlanLoading.value ||
        orderLoading.value
    ),
  };
};
